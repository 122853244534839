<template>
  <div class="telemedicine-management contact-list pr-3">
    <div class="card radius-10 pt-2 pl-4 pr-4 border-0">
      <div class="table-responsive">
        <table class="table table-vcenter table-mobile-md card-table table-scroll">
          <thead class="d-none d-md-table">
            <tr>
              <th>{{ $t('lbl_date') }}</th>
              <th class="text-sm-center">KG</th>
              <th class="text-sm-center">CM</th>
              <th class="text-sm-center">BPM</th>
              <th class="text-sm-center">mmHg</th>
              <th class="text-sm-center">{{ $t('lbl_times_minutes') }}</th>
              <th class="text-sm-center">{{ $t('lbl_degree_celsius') }}</th>
            </tr>
          </thead>
          <tbody
            v-if="healts && healts.count"
            id="contacts"
            :class="healts && healts.count == 10 ? 'h-100' : ''"
          >
            <tr
              class="mt-2 table-tr"
              v-for="(healt, index) in healts.data"
              style="height: 70px;"
              :key="index"
            >
              <td data-label="Bệnh sử">
                <span
                  class="robo-16-400 pl-2"
                >{{healt && healt.created_at ? formatDateDDMMYYY(healt.created_at) : ''}}</span>
              </td>
              <td data-label="KG" class="text-sm-center">
                <span class="robo-16-400">{{healt && healt.weight}}</span>
              </td>
              <td data-label="CM" class="text-sm-center">
                <span class="robo-16-400">{{healt && healt.height}}</span>
              </td>
              <td data-label="BPM" class="text-sm-center">
                <span class="robo-16-400">{{healt && healt.tim_mach}}</span>
              </td>
              <td data-label="mmHg" class="text-sm-center">
                <span class="robo-16-400">{{healt && healt.huyet_ap}}</span>
              </td>
              <td data-label="Lần/phút" class="text-sm-center">
                <span class="robo-16-400">{{healt && healt.nhip_tho}}</span>
              </td>
              <td data-label="Độ C" class="text-sm-center">
                <span class="robo-16-400">{{healt && healt.nhiet_do}}</span>
              </td>
            </tr>
            <tr>
              <td colspan="3" v-if="groups && !groups.data && is_loaded">
                <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
                  <img
                    src="../../../public/assets/images/not-found-search.png"
                    class="img-responsive p-0"
                    alt
                  />
                  <p
                    class="robo-16-400 text-black"
                  >{{$t(search ? 'lbl_no_records_match_your_search' : 'lbl_you_have_not_had_any_patient_yet' )}}</p>
                </div>
              </td>
            </tr>
            <tr v-for="i in 10" :key="i">
              <td>
                <div class="skeleton-avatar avatar-sm avatar-rounded"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
              <td>
                <div class="skeleton-line"></div>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          v-if="healts && healts.count && is_loaded"
          :items="healts"
          :current_page="current_page"
          @onRefresh="getPersonHistories"
        ></Pagination>
        <div v-if="healts && !healts.count && !loading">
          <div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center overflow-hidden">
            <img
              src="../../../public/assets/images/not-found-search.png"
              class="img-responsive p-0"
              alt
            />
            <p
              class="robo-16-400 text-black"
            >{{$t(search ? 'lbl_hodo_not_found_hssk' : 'lbl_no_general_healthcheck_has_been_made') }}</p>
          </div>
        </div>
      </div>
      <br />
    </div>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils'
import Pagination from '../../components/Pagination'
export default {
  components: { Pagination },
  name: 'generalHealth',
  computed: {},
  data () {
    return {
      healts: null,
      is_loaded: true,
      search: '',
      params: {
        limit: '',
        sort: '',
        sort_by: '',
        search: '',
        page: 1
      },
      type: 'contact-doctor',
      sort: 0,
      loading: false,
      user: null,
      clinic: null,
      list_total_request: null,
      load_end: false,
      current_page: 1,
      person_id: 0
    }
  },
  mounted () {
    let self = this
    self.getPersonHistories()
    self.person_id = self.$route?.params?.id
  },
  methods: {
    isDoctor () {
      return appUtils.isDoctor()
    },
    isAdmin () {
      return appUtils.isAdmin()
    },
    async getPersonHistories () {
      let self = this
      try {
        let res = await self.$rf
          .getRequest('AuthRequest')
          .getPersonHistories(self.$route?.params?.id, self.params)
        self.healts = res.data
      } catch (e) {
        console.log(e)
      }
    },
    gotoPersonDetail () {
      let self = this
      self.$router.push({ path: '' })
    },
    formatPhoneNumber (number) {
      let cleaned = ('' + number).replace(/\D/g, '')
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        let hiden_number = ''
        for (var i = 0; i < match[2].length; i++) {
          hiden_number += 'X'
        }
        return match[1] + '-' + hiden_number + '-' + match[3]
      }
      return null
    },
    getImageURL (path) {
      return process.env.VUE_APP_BASE_IMAGE_URL + 'storage/' + path
    },
    formatDateDDMMYYY (time) {
      return window.moment(time).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>
<style scoped>
</style>